import { useEffect, Suspense, lazy, CSSProperties, useState } from "react";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import {
  SpeakerNotes,
  useRoomControl,
  SlideContextProvider,
  Gallery,
  ImageWithEmbeds,
  Pano360,
  TextWithBackground,
  Video,
  Wireframe,
  GalleryWithHotspots,
  Object3D,
  MultiDisplayGallery,
} from "@showhereco/ui";
import { useAppDispatch, useAppSelector } from "Store";
import {
  setGallery,
  setHotspotIndex,
  setImageIndex,
  setHotspotItemIndex,
  setGallerySlide,
  setShowModal,
  setKeyValue,
  setVideo,
  setNestedVideo,
  toggleHawkeye,
  setPano360ControlsEnabled,
  set3DControlsEnabled,
  setMultiDisplayGallerySlide,
} from "Store/slides";
import { goTo } from "Store/structure";
import { setDisabled as setSwipeableDisabled } from "Store/swipeable";
import { socket as localSocket } from "Store/syncLocal";
import {
  selectScreenshareActive,
  selectScreenshareRoom,
  socket as remoteSocket,
} from "Store/syncRemote";
import {
  hasUiControl,
  roomControlConfig,
  role,
  isScreenShareViewer,
} from "config";
import { useAnalytics } from "App";
import type {
  Slide as TSlide,
  SlideWithState as TSlideWithState,
  SlideState,
  GallerySlide,
  VideoSlide,
  Object3DSlide,
  WireframeSlide,
  MultiDisplayGallerySlide,
  GalleryWithHotspotsSlide,
  ListGallerySlide,
  TextWithBackgroundSlide,
  GroupedGalleriesSlide,
  ImageWithHotspotsSlide,
  CustomFloorplansSlide,
  View360Slide,
  BishopsgateZoomableGallerySlide,
  BishopsgateVideoBackgroundSlide,
  WhiteCityPlaceAccordionSlide,
  WhiteCityPlaceFloorplansSlide,
  WoodCrescentFloorplansSlide,
  CustomScheduleSlide,
} from "Store/slides";
// import { useGLTF } from "@react-three/drei";

const ListGallery = lazy(() => import("../SlideTypes/ListGallery"));
const GroupedGalleries = lazy(() => import("../SlideTypes/GroupedGalleries"));
const BISHOPSGATE8_FLOORPLANS = lazy(
  () => import("../SlideTypes/_8Bishopsgate/FloorPlans")
);
const BISHOPSGATE8_ZOOMABLEGALLERY = lazy(
  () => import("../SlideTypes/_8Bishopsgate/ZoomableGallery/ZoomableGallery")
);
const BISHOPSGATE8_VIDEOBACKGROUND = lazy(
  () => import("../SlideTypes/_8Bishopsgate/VideoBackground/VideoBackground")
);
const BISHOPSGATE8_SPECGRID = lazy(
  () => import("../SlideTypes/_8Bishopsgate/SpecGrid/SpecGrid")
);
const WHITECITYPLACE_ACCORDION = lazy(
  () => import("../SlideTypes/_WhiteCityPlace/Accordion")
);
const WHITECITYPLACE_AERIAL = lazy(
  () => import("../SlideTypes/_WhiteCityPlace/Aerial")
);
const WHITECITYPLACE_FLOORPLANS = lazy(
  () => import("../SlideTypes/_WhiteCityPlace/FloorPlans")
);
const WHITECITYPLACE_SCHEDULE = lazy(
  () => import("../SlideTypes/_WhiteCityPlace/Schedule")
);
const WHITECITYPLACE_SITEPLAN = lazy(
  () => import("../SlideTypes/_WhiteCityPlace/SitePlan")
);
const WOODCRESCENT_FLOORPLANS = lazy(
  () => import("../SlideTypes/_WoodCrescent/FloorPlans")
);
const WOODCRESCENT_SCHEDULE = lazy(
  () => import("../SlideTypes/_WoodCrescent/Schedule")
);

type SlideProps = TSlide & TSlideWithState;

const Slide = (props: Partial<SlideProps>) => {
  const dispatch = useAppDispatch();
  const [videoIsMuted, setVideoIsMuted] = useState(isScreenShareViewer);
  const swipeableDisabled = useAppSelector((state) => state.swipeable.disabled);
  const screenshareActive = useSelector(selectScreenshareActive);
  const screenshareRoom = useSelector(selectScreenshareRoom);
  const handleAnalyticsEvent = useAnalytics();
  const { sendCommand } = useRoomControl({ roomControlConfig, role });

  const renderSlide = (slide: SlideProps) => {
    const sharedProps = {
      analyticsEventCallback:
        props.analyticsEventCallback || handleAnalyticsEvent,
      position: slide.position,
      isCurrent: slide.isCurrent,
      typography: slide.typography,
      slideId: slide.slideId,
      slideType: slide.slideType,
      sendRoomControlCommand: sendCommand,
      screenshareActive,
      screenshareRoom,
      role,
      hasUiControl,
      roomControlConfig,
      setSwipeableDisabled: (disabled: boolean) =>
        void dispatch(setSwipeableDisabled(disabled)),
    };

    const camera3dControls = {
      localSocket,
      remoteSocket,
      controlsEnabled: false,
      setControlsEnabled: (enabled: boolean) => {},
      panoControlsEnabled: false,
      setPanoControlsEnabled: (enabled: boolean) => {},
    };

    const handleSetPanoControlsEnabled = (enabled: boolean) =>
      void dispatch(
        setPano360ControlsEnabled({ position: slide.position, enabled })
      );
    const handleSet3DControlsEnabled = (enabled: boolean) =>
      void dispatch(
        set3DControlsEnabled({ position: slide.position, enabled })
      );

    if (slide.type === "Pano360") {
      camera3dControls.controlsEnabled = !!slide.state?.controlsEnabled;
      camera3dControls.setControlsEnabled = handleSetPanoControlsEnabled;
    } else if (slide.type === "Object3D") {
      camera3dControls.controlsEnabled = !!slide.state?.controlsEnabled;
      camera3dControls.setControlsEnabled = handleSet3DControlsEnabled;
    }

    if (slide.state?.pano360) {
      camera3dControls.panoControlsEnabled =
        !!slide.state?.pano360.controlsEnabled;
      camera3dControls.setPanoControlsEnabled = handleSetPanoControlsEnabled;
    }

    switch (slide.type) {
      case "Gallery": {
        const s = slide as GallerySlide & SlideState;

        return (
          <Gallery
            {...sharedProps}
            {...camera3dControls}
            media={s.media}
            pagerFormat={s.pagerFormat}
            gallerySlide={s.state?.gallerySlide}
            setGallerySlide={(index: number) =>
              dispatch(
                setGallerySlide({ position: s.position, gallerySlide: index })
              )
            }
            overviewGrid={s.overviewGrid}
            hawkeye={s.state?.hawkeye}
            toggleHawkeye={() => {
              dispatch(toggleHawkeye(s.position));
            }}
            swipeableDisabled={swipeableDisabled}
            setSwipeableDisabled={(disabled: boolean) => {
              dispatch(setSwipeableDisabled(disabled));
            }}
            videoShouldPlay={s.state?.video?.shouldPlay}
            setVideoShouldPlay={(shouldPlay: boolean) => {
              dispatch(setNestedVideo({ position: s.position, shouldPlay }));
            }}
            videoSelectedTimecode={s.state?.video?.selectedTimecode}
            setVideoSelectedTimecode={(selectedTimecode: number) => {
              dispatch(
                setNestedVideo({ position: s.position, selectedTimecode })
              );
            }}
          />
        );
      }

      case "ListGallery": {
        const s = slide as ListGallerySlide & SlideState;
        return (
          <ListGallery
            {...sharedProps}
            typographyVariant={s.typographyVariant}
            sections={s.sections}
            media={s.media}
            activeGallery={undefined}
            gallerySlide={s.state?.gallerySlide}
            setGallerySlide={(gallerySlide: number) => {
              dispatch(setGallerySlide({ position: s.position, gallerySlide }));
            }}
            showModal={s.state?.showModal}
            setShowModal={(showModal: boolean) =>
              dispatch(setShowModal({ position: s.position, showModal }))
            }
          />
        );
      }

      case "Object3D": {
        // useGLTF.preload(slide.model); //? for future reference
        const s = slide as Object3DSlide & SlideState;
        return (
          <Object3D
            {...sharedProps}
            {...camera3dControls}
            model={s.model}
            poster={s.model.poster}
          />
        );
      }

      case "TextWithBackground": {
        const s = slide as TextWithBackgroundSlide & SlideState;
        return (
          <TextWithBackground
            {...sharedProps}
            backgroundMedia={s.media}
            text={s.text}
            typographyVariant={s.typographyVariant}
            textAlignment={s.textAlignment}
            hawkeye={s.state?.hawkeye}
            toggleHawkeye={() => {
              dispatch(toggleHawkeye(s.position));
            }}
          />
        );
      }

      case "GroupedGalleries": {
        const s = slide as GroupedGalleriesSlide & SlideState;
        return (
          <GroupedGalleries
            {...sharedProps}
            galleries={s.galleries}
            activeGallery={s.state?.gallery}
            gallerySlide={s.state?.gallerySlide}
            setGallery={(gallery: number) => {
              dispatch(setGallery({ position: s.position, gallery }));
            }}
            setGallerySlide={(gallerySlide: number) => {
              dispatch(setGallerySlide({ position: s.position, gallerySlide }));
            }}
            showModal={s.state?.showModal}
            setShowModal={(showModal: boolean) =>
              dispatch(setShowModal({ position: s.position, showModal }))
            }
          />
        );
      }

      case "Video": {
        const s = slide as VideoSlide & SlideState;

        return (
          <Video
            {...sharedProps}
            autoPlay={s.autoPlay}
            chapters={s.chapters}
            fit={s.fit}
            poster={s.media.poster}
            source={s.media.source}
            sourceIpad={s.media.sourceIpad}
            controlsFormat={s.controlsFormat}
            loop={s.loop}
            shouldPlay={s.state?.shouldPlay}
            setShouldPlay={(shouldPlay: boolean) => {
              dispatch(setVideo({ position: s.position, shouldPlay }));
            }}
            selectedTimecode={s.state?.selectedTimecode}
            setSelectedTimecode={(selectedTimecode: number) => {
              dispatch(setVideo({ position: s.position, selectedTimecode }));
            }}
          />
        );
      }

      case "Pano360": {
        const s = slide as View360Slide & SlideState;
        return (
          <Pano360
            {...sharedProps}
            {...camera3dControls}
            media={s.media}
            gallerySlide={s.state?.gallerySlide}
            setGallerySlide={(gallerySlide: number) => {
              dispatch(setGallerySlide({ position: s.position, gallerySlide }));
            }}
          />
        );
      }

      case "GalleryWithHotspots": {
        const s = slide as GalleryWithHotspotsSlide & SlideState;
        return (
          <GalleryWithHotspots
            {...sharedProps}
            {...camera3dControls}
            images={s.images}
            imageIndex={s.state?.imageIndex}
            setImageIndex={(index: number) => {
              dispatch(setImageIndex({ position: s.position, index }));
            }}
            hotspotIndex={s.state?.hotspotIndex}
            overviewGrid={s.overviewGrid}
            setHotspotIndex={(index: number | null) => {
              dispatch(setHotspotIndex({ position: s.position, index }));
            }}
            hotspotItemIndex={s.state?.hotspotGalleryItemIndex}
            setHotspotItemIndex={(index: number) => {
              dispatch(setHotspotItemIndex({ position: s.position, index }));
            }}
            showModal={s.state?.showModal}
            pagerFormat={s.pagerFormat.value}
            setShowModal={(showModal: boolean) =>
              dispatch(setShowModal({ position: s.position, showModal }))
            }
            videoShouldPlay={s.state?.video?.shouldPlay}
            setVideoShouldPlay={(shouldPlay: boolean) => {
              dispatch(setNestedVideo({ position: s.position, shouldPlay }));
            }}
            videoSelectedTimecode={s.state?.video?.selectedTimecode}
            setVideoSelectedTimecode={(selectedTimecode: number) => {
              dispatch(
                setNestedVideo({ position: s.position, selectedTimecode })
              );
            }}
          />
        );
      }

      case "ImageWithEmbeds": {
        const s = slide as ImageWithHotspotsSlide & SlideState;

        const preparedImageWithEmbeds = (
          <ImageWithEmbeds
            {...sharedProps}
            {...camera3dControls}
            image={s.image}
            showModal={s.state?.showModal}
            setShowModal={(showModal: boolean) =>
              dispatch(setShowModal({ position: slide.position, showModal }))
            }
            activeGallery={s.state?.gallery}
            setGallery={(gallery: number) =>
              dispatch(setGallery({ position: s.position, gallery }))
            }
            gallerySlide={s.state?.gallerySlide}
            setGallerySlide={(gallerySlide: number) => {
              dispatch(setGallerySlide({ position: s.position, gallerySlide }));
            }}
            videoShouldPlay={s.state?.video?.shouldPlay}
            setVideoShouldPlay={(shouldPlay: boolean) => {
              dispatch(setNestedVideo({ position: s.position, shouldPlay }));
            }}
            videoSelectedTimecode={s.state?.video?.selectedTimecode}
            setVideoSelectedTimecode={(selectedTimecode: number) => {
              dispatch(
                setNestedVideo({ position: s.position, selectedTimecode })
              );
            }}
            swipeableDisabled={swipeableDisabled}
          />
        );

        const legacyNamespaces = [
          "1WoodCrescent",
          "8Bishopsgate",
          "BrentCrossTown",
          "TheAcre",
          "WhiteCity",
          "WhiteCityPlace",
        ];
        if (
          legacyNamespaces.includes(
            process.env.REACT_APP_NAMESPACE ?? "undefined"
          )
        ) {
          return preparedImageWithEmbeds;
        } else {
          const widescreenContainerStyles: CSSProperties = {
            position: "absolute",
            width: "100%",
            height: "auto",
            aspectRatio: "16/9",
            overflow: "none",
            top: "50%",
            transform: "translateY(-50%)",
          };
          return (
            <div style={widescreenContainerStyles}>
              {preparedImageWithEmbeds}
            </div>
          );
        }
      }

      case "Wireframe": {
        const s = slide as WireframeSlide & SlideState;
        return (
          <Wireframe
            {...sharedProps}
            title={s.title}
            description={s.description}
          />
        );
      }

      case "MultiDisplayGallery": {
        const s = slide as MultiDisplayGallerySlide & SlideState;
        return (
          <MultiDisplayGallery
            {...sharedProps}
            {...camera3dControls}
            displays={s.displays}
            gallerySlide={s.state?.gallerySlide}
            setGallerySlide={(index: number) =>
              dispatch(
                setMultiDisplayGallerySlide({
                  position: s.position,
                  gallerySlide: index,
                })
              )
            }
            swipeableDisabled={swipeableDisabled}
            setSwipeableDisabled={(disabled: boolean) => {
              dispatch(setSwipeableDisabled(disabled));
            }}
            videoShouldPlay={s.state?.video?.shouldPlay}
            setVideoShouldPlay={(shouldPlay: boolean) => {
              dispatch(setNestedVideo({ position: s.position, shouldPlay }));
            }}
            videoSelectedTimecode={s.state?.video?.selectedTimecode}
            setVideoSelectedTimecode={(selectedTimecode: number) => {
              dispatch(
                setNestedVideo({ position: s.position, selectedTimecode })
              );
            }}
            mutedVideo={false}
          />
        );
      }

      case "BISHOPSGATE8_FLOORPLANS": {
        const s = slide as CustomFloorplansSlide & SlideState;
        return (
          <BISHOPSGATE8_FLOORPLANS
            {...sharedProps}
            selectedLevelIndex={s.state?.selectedLevelIndex}
            setSelectedLevelIndex={(value: number) => {
              dispatch(
                setKeyValue({
                  position: slide.position,
                  key: "selectedLevelIndex",
                  value,
                })
              );
            }}
            showSpaceplans={s.state?.showSpaceplans}
            setShowSpaceplans={(value: boolean) => {
              dispatch(
                setKeyValue({
                  position: s.position,
                  key: "showSpaceplans",
                  value,
                })
              );
            }}
            activeGallery={s.state?.gallery}
            transform={s.state?.transform}
            setGallery={(gallery: number | null) =>
              dispatch(setGallery({ position: slide.position, gallery }))
            }
            gallerySlide={s.state?.gallerySlide}
            setGallerySlide={(gallerySlide: number) => {
              dispatch(setGallerySlide({ position: s.position, gallerySlide }));
            }}
            showModal={s.state?.showModal}
            setShowModal={(showModal: boolean) =>
              dispatch(setShowModal({ position: s.position, showModal }))
            }
          />
        );
      }

      case "BISHOPSGATE8_SPECGRID": {
        const s = slide as CustomFloorplansSlide & SlideState;
        return (
          <BISHOPSGATE8_SPECGRID
            {...sharedProps}
            active={s.state?.active}
            setActive={(value: number) => {
              dispatch(
                setKeyValue({ position: s.position, key: "active", value })
              );
            }}
          />
        );
      }

      case "BISHOPSGATE8_ZOOMABLEGALLERY": {
        const s = slide as BishopsgateZoomableGallerySlide & SlideState;
        return (
          <BISHOPSGATE8_ZOOMABLEGALLERY
            {...sharedProps}
            areas={s.areas}
            backgroundSource={s.source}
            backgroundWideSource={s.sourceWidescreen}
            active={s.state?.active}
            setActive={(index: number | null) => {
              dispatch(
                setKeyValue({
                  position: s.position,
                  key: "active",
                  value: s.state?.active === index ? null : index,
                })
              );
            }}
          />
        );
      }

      case "BISHOPSGATE8_VIDEOBACKGROUND": {
        const s = slide as BishopsgateVideoBackgroundSlide & SlideState;
        return (
          <BISHOPSGATE8_VIDEOBACKGROUND
            {...sharedProps}
            media={s.media}
            withFloorGuide={s.withFloorGuide}
            floorGuideSource={s.floorGuideSource}
            text={s.text}
            autoHeight={s.autoHeight}
            sizeOverride={s.sizeOverride}
            loop={s.loop}
            shouldPlay={s.state?.shouldPlay}
            setShouldPlay={(shouldPlay: boolean) => {
              dispatch(setVideo({ position: s.position, shouldPlay }));
            }}
          />
        );
      }

      case "WHITECITYPLACE_ACCORDION": {
        const s = slide as WhiteCityPlaceAccordionSlide & SlideState;
        return (
          <WHITECITYPLACE_ACCORDION
            {...sharedProps}
            headline={s.headline}
            items={s.items}
            active={s.state?.active}
            setActive={(value: number) => {
              dispatch(
                setKeyValue({ position: s.position, key: "active", value })
              );
            }}
          />
        );
      }

      case "WHITECITYPLACE_AERIAL": {
        const s = slide;
        return (
          <WHITECITYPLACE_AERIAL
            {...sharedProps}
            activeGallery={s.state?.gallery}
            gallerySlide={s.state?.gallerySlide}
            setGallery={(gallery: number) =>
              dispatch(setGallery({ position: s.position, gallery }))
            }
            setGallerySlide={(gallerySlide: number) => {
              dispatch(setGallerySlide({ position: s.position, gallerySlide }));
            }}
            showModal={s.state?.showModal}
            setShowModal={(showModal: boolean) =>
              dispatch(setShowModal({ position: s.position, showModal }))
            }
          />
        );
      }

      case "WHITECITYPLACE_FLOORPLANS": {
        const s = slide as WhiteCityPlaceFloorplansSlide & SlideState;
        return (
          <WHITECITYPLACE_FLOORPLANS
            {...sharedProps}
            buildingKey={s.buildingKey}
            transform={s.state?.transform}
            selectedLevelIndex={s.state?.selectedLevelIndex}
            setSelectedLevelIndex={(value: number) => {
              dispatch(
                setKeyValue({
                  position: s.position,
                  key: "selectedLevelIndex",
                  value,
                })
              );
            }}
            showSpaceplans={s.state?.showSpaceplans}
            setShowSpaceplans={(value: boolean) => {
              dispatch(
                setKeyValue({
                  position: s.position,
                  key: "showSpaceplans",
                  value,
                })
              );
            }}
            activeGallery={s.state?.gallery}
            setGallery={(gallery: number) =>
              dispatch(setGallery({ position: s.position, gallery }))
            }
            gallerySlide={s.state?.gallerySlide}
            setGallerySlide={(gallerySlide: number) => {
              dispatch(setGallerySlide({ position: s.position, gallerySlide }));
            }}
            showModal={s.state?.showModal}
            setShowModal={(showModal: boolean) =>
              dispatch(setShowModal({ position: s.position, showModal }))
            }
          />
        );
      }

      case "WOODCRESCENT_FLOORPLANS": {
        const s = slide as WoodCrescentFloorplansSlide & SlideState;
        return (
          <WOODCRESCENT_FLOORPLANS
            {...sharedProps}
            buildingKey={s.buildingKey}
            transform={s.state?.transform}
            selectedLevelIndex={s.state?.selectedLevelIndex}
            setSelectedLevelIndex={(value: number) => {
              dispatch(
                setKeyValue({
                  position: s.position,
                  key: "selectedLevelIndex",
                  value,
                })
              );
            }}
            showSpaceplans={s.state?.showSpaceplans}
            setShowSpaceplans={(value: boolean) => {
              dispatch(
                setKeyValue({
                  position: s.position,
                  key: "showSpaceplans",
                  value,
                })
              );
            }}
            activeGallery={s.state?.gallery}
            setGallery={(gallery: number) =>
              dispatch(setGallery({ position: s.position, gallery }))
            }
            gallerySlide={s.state?.gallerySlide}
            setGallerySlide={(gallerySlide: number) => {
              dispatch(setGallerySlide({ position: s.position, gallerySlide }));
            }}
            showModal={s.state?.showModal}
            setShowModal={(showModal: boolean) =>
              dispatch(setShowModal({ position: s.position, showModal }))
            }
          />
        );
      }

      case "WHITECITYPLACE_SCHEDULE": {
        const s = slide as CustomScheduleSlide & SlideState;
        return (
          <WHITECITYPLACE_SCHEDULE
            {...sharedProps}
            image={s.image}
            buildingKey={s.buildingKey}
          />
        );
      }

      case "WHITECITYPLACE_SITEPLAN": {
        const s = slide;
        return (
          <WHITECITYPLACE_SITEPLAN
            {...sharedProps}
            active={s.state?.active}
            setActive={(value: number) => {
              dispatch(
                setKeyValue({
                  position: s.position,
                  key: "active",
                  value,
                })
              );
            }}
            goTo={(columnIndex: number) => {
              const position = {
                s: s.position.s,
                x: columnIndex,
                y: 0,
              };
              dispatch(goTo({ position }));
            }}
          />
        );
      }

      case "WOODCRESCENT_SCHEDULE": {
        const s = slide as CustomScheduleSlide & SlideState;
        return (
          <WOODCRESCENT_SCHEDULE
            {...sharedProps}
            image={s.image}
            buildingKey={s.buildingKey}
          />
        );
      }

      case undefined: {
        console.warn("No defined slide.type", slide.type, slide.title);
        const key = `placeholder-${props.position?.s}-${props.position?.x}-${props.position?.y}`;
        return (
          <div
            key={key}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#333",
              fontSize: "7vh",
            }}
          >
            {slide.title}
          </div>
        );
      }

      default: {
        console.warn("Unknown slide.type", slide.type, slide.title);
        const key = `placeholder-${props.position?.s}-${props.position?.x}-${props.position?.y}`;
        return (
          <div key={key} className="unavailable-slide-type">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </svg>
              <p>
                <strong>Your app needs to be updated</strong>
              </p>
              <p>
                This slide uses a type of content which is not yet available to
                you.
                <br />
                Please update your app to present this slide.
              </p>
              <p>
                <code>{slide.type}</code>
              </p>
            </div>
          </div>
        );
      }
    }
  };

  const { isCurrent, roomControlCommands = [] } = props;
  useEffect(() => {
    if (isCurrent && role === "broadcast") {
      roomControlCommands.forEach((string) => sendCommand(string));
    }
  }, [isCurrent, roomControlCommands, sendCommand]);

  const { position } = props;
  if (position === undefined) return null;
  const portalIdModal = `portal_${position.s}-${position.x}-${position.y}`;

  return (
    <>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <SlideContextProvider
          value={{ isCurrent: props.isCurrent, videoIsMuted, setVideoIsMuted }}
        >
          <Suspense fallback={null}>
            {renderSlide(props as SlideProps)}
          </Suspense>
        </SlideContextProvider>
      </Sentry.ErrorBoundary>

      <div id={portalIdModal} />

      {props.speakerNotes &&
        props.speakerNotes?.body !== "<p></p>" &&
        hasUiControl && (
          <SpeakerNotes
            title={props.speakerNotes?.title}
            body={props.speakerNotes?.body}
            isCurrent={props.isCurrent}
            position={props.position}
          />
        )}
    </>
  );
};

function ErrorFallback() {
  return (
    <div className="error-fallback">
      <div className="error-fallback__body">
        <h1>This slide isn't working right now</h1>
        <p>You can continue to present other slides</p>
      </div>
      <div className="error-fallback__footer">
        <p>Technical support has been notified</p>
      </div>
    </div>
  );
}

export default Slide;
